import React from "react"

interface Props {

}

export const FullLogo: React.FC<Props> = ({ }) => {
    return (
        <svg className="brand mobile-hide" width="250" height="49" viewBox="0 0 250 49">
            <g>
                <g>
                    <path d="M7.1,22.6c0.1-0.2,0.1-0.4,0.2-0.6c1.4-3.5,4.7-4.9,4.7-4.9c4.1-1.9,8.5,0,8.5,0c2.9-7.6,8.5-6.2,9.4-6
                        c1.8-2.9,5-4.9,8.7-4.9c5.6,0,10.1,4.5,10.1,10.1c0,0.8-0.1,1.6-0.3,2.4c3.4,0.7,6,2.2,6.4,2.4c0-8.4-0.4-13.5-3.8-16.8
                        c-3.8-3.8-9.6-3.8-20.1-3.8c-10.4,0-16.3,0-20,3.8C7.3,8,7,13.3,7.1,22.6L7.1,22.6z"/>
                    <path d="M61.5,34.6c-1.8,1.6-4.2,2.5-6.5,3.2c-2.6,0.7-5.3,1.2-8.1,1.4c-4.4,0.4-8.8,0.1-12.8-2
                        c-1-0.5-1.9-1.1-2.7-1.8c-0.1-0.1-1-0.8-0.9-0.8c-2.9,4.8-9.5,4.4-9.5,4.4c-5.6-0.1-8.4-5.3-8.4-5.3c0.1,0.1-2.7,0.9-2.9,1
                        c-3.1,0.7-6.5,0.6-9.7,0.1c3.1,2.6,6.2,3.9,7.6,4.4c0.6,2.2,1.5,4,2.9,5.4c3.8,3.8,9.7,3.8,20.1,3.8c10.5,0,16.3,0,20.1-3.8
                        c1.1-1.1,1.8-2.3,2.4-3.8C57.5,40,61.5,34.6,61.5,34.6z"/>
                </g>
                <g>
                    <path d="M71.8,19.5h-0.3c-0.7,0-1.2-0.5-1.2-1.2l0,0c0-0.7,0.5-1.2,1.2-1.2h0.3c0.7,0,1.2,0.5,1.2,1.2l0,0
                        C73,19,72.5,19.5,71.8,19.5z M73,31.8h-2.7V21H73V31.8z"/>
                    <path d="M76,31.8V17.4h4.7c0.7,0,1.3,0,1.7,0.1c1.1,0.1,2.1,0.5,2.9,1.2c0.8,0.7,1.4,1.5,1.8,2.5
                        c0.4,1,0.6,2.1,0.6,3.4c0,1.3-0.2,2.4-0.6,3.4c-0.4,1-1,1.9-1.8,2.5s-1.8,1-2.9,1.2c-0.4,0.1-1,0.1-1.7,0.1L76,31.8L76,31.8z
                        M80.7,29.3c0.7,0,1.2,0,1.6-0.1c0.6-0.1,1.1-0.4,1.5-0.9s0.7-1,0.9-1.6c0.2-0.7,0.3-1.3,0.3-2c0-0.7-0.1-1.4-0.3-2.1
                        c-0.2-0.7-0.5-1.2-0.9-1.6c-0.4-0.4-0.9-0.7-1.5-0.8C81.9,20,81.3,20,80.7,20h-1.9v9.2L80.7,29.3L80.7,29.3z"/>
                    <path d="M95.9,21c0.2,0,0.4,0,0.6,0v2.5c-0.2-0.1-0.5-0.1-0.8-0.1c-0.5,0-1,0.1-1.3,0.3c-0.5,0.2-0.8,0.5-1.1,1
                        c-0.2,0.4-0.4,1-0.4,1.6v5.5h-2.7V21h2.4v1.7c0.3-0.5,0.7-1,1.2-1.2C94.4,21.2,95.1,21,95.9,21z"/>
                    <path d="M100.5,27.2c0.1,0.8,0.4,1.4,0.8,1.8c0.4,0.4,1,0.6,1.7,0.6c0.5,0,1-0.1,1.4-0.4c0.4-0.2,0.7-0.6,0.9-1
                        L108,29c-0.3,0.7-0.7,1.2-1.1,1.7c-0.5,0.5-1,0.8-1.7,1.1c-0.6,0.2-1.3,0.4-2,0.4c-1.1,0-2.1-0.2-2.9-0.7c-0.8-0.5-1.5-1.1-2-2
                        s-0.7-1.8-0.7-2.9c0-1.2,0.2-2.2,0.7-3.1c0.5-0.9,1.1-1.6,1.9-2.1c0.8-0.5,1.8-0.7,2.8-0.7c1,0,1.9,0.2,2.7,0.7
                        c0.8,0.4,1.3,1.1,1.8,2c0.4,0.8,0.6,1.8,0.6,3c0,0.2,0,0.4,0,0.8C108.1,27.2,100.5,27.2,100.5,27.2z M100.6,25.2h4.7
                        c-0.1-0.7-0.3-1.3-0.7-1.6c-0.4-0.3-0.9-0.5-1.5-0.5C101.7,23.1,100.9,23.8,100.6,25.2z"/>
                    <path d="M119.1,23.8c0.1,0.4,0.1,0.8,0.1,1.4v6.6h-2.4v-1.3c-0.5,0.6-1,1-1.6,1.2c-0.6,0.2-1.3,0.4-2.1,0.4
                        c-0.7,0-1.4-0.1-1.9-0.4c-0.5-0.3-1-0.7-1.2-1.2s-0.4-1-0.4-1.7c0-0.8,0.2-1.5,0.5-2c0.4-0.5,1-1,1.8-1.3c0.5-0.2,1-0.3,1.7-0.4
                        c0.7-0.1,1.6-0.3,2.9-0.5c-0.1-0.5-0.2-0.9-0.6-1.1c-0.3-0.2-0.8-0.4-1.4-0.4c-0.4,0-0.9,0.1-1.3,0.3s-0.7,0.5-0.8,1l-2.4-0.8
                        c0.3-0.9,0.8-1.7,1.6-2.2c0.8-0.5,1.8-0.8,3-0.8c2.1,0,3.5,0.7,4.2,2C118.9,23,119,23.4,119.1,23.8z M116.5,26.9
                        c-0.9,0.1-1.6,0.3-2.1,0.4c-0.4,0.1-0.8,0.2-1.1,0.3c-0.4,0.1-0.7,0.3-0.9,0.5c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.4,0.1,0.7,0.4,0.9
                        c0.3,0.2,0.6,0.3,1.1,0.3c0.4,0,0.9-0.1,1.2-0.2c0.4-0.1,0.6-0.4,0.8-0.6s0.4-0.4,0.4-0.6C116.4,28.2,116.5,27.6,116.5,26.9z"/>
                    <path d="M135.8,21.3c0.6,0.3,1,0.8,1.3,1.4s0.4,1.3,0.4,2.1v7.1h-2.7v-6.4c0-0.7-0.2-1.3-0.5-1.7s-0.8-0.6-1.4-0.6
                        c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.7,0.7c-0.1,0.3-0.2,0.7-0.2,1.1v6.5h-2.7v-6.4c0-0.7-0.2-1.3-0.5-1.7
                        c-0.3-0.4-0.8-0.6-1.4-0.6c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.7,0.7s-0.2,0.7-0.2,1.1v6.5h-2.7V21h2.4v1.2
                        c0.4-0.4,0.8-0.8,1.4-1c0.5-0.2,1.1-0.4,1.8-0.4c0.7,0,1.4,0.1,1.9,0.4c0.5,0.3,0.9,0.7,1.2,1.2c0.4-0.5,0.8-0.9,1.4-1.2
                        c0.6-0.3,1.2-0.4,1.9-0.4C134.5,20.8,135.2,21,135.8,21.3z"/>
                    <path d="M147,31.8h-2.7V17.4h2.7V31.8z" />
                    <path d="M158.7,21.7c0.6,0.7,1,1.3,1.2,2.1c0.1,0.7,0.2,1.4,0.2,2v6.1h-2.7v-5.1c0-0.5,0-1-0.1-1.6
                        c-0.1-0.5-0.3-0.9-0.6-1.3c-0.3-0.4-0.9-0.6-1.6-0.6c-0.6,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1.3-0.7,2.4v5.5H150V21h2.4v1.1
                        c0.4-0.4,0.9-0.8,1.5-1s1.3-0.4,2-0.4C157.1,20.7,158.1,21,158.7,21.7z"/>
                    <path d="M166,23.1v3.9v0.6c0,0.4,0,0.6,0,0.8c0,0.2,0.1,0.4,0.2,0.5c0.3,0.4,0.8,0.7,1.6,0.7c0.3,0,0.7,0,1.3-0.1v2.3
                        c-0.7,0.1-1.3,0.2-1.9,0.2c-0.8,0-1.5-0.1-2.1-0.4c-0.6-0.2-1-0.6-1.3-1.2c-0.2-0.3-0.3-0.7-0.4-1c-0.1-0.4-0.1-0.7-0.1-1.2v-1
                        v-4.1h-1.8V21h1.8v-3h2.7v3h3.1v2.1H166z"/>
                    <path d="M173.2,27.2c0.1,0.8,0.4,1.4,0.8,1.8c0.4,0.4,1,0.6,1.7,0.6c0.5,0,1-0.1,1.4-0.4c0.4-0.2,0.7-0.6,0.9-1
                        l2.7,0.8c-0.3,0.7-0.7,1.2-1.1,1.7c-0.5,0.5-1,0.8-1.7,1.1c-0.6,0.2-1.3,0.4-2,0.4c-1.1,0-2.1-0.2-2.9-0.7c-0.8-0.5-1.5-1.1-2-2
                        c-0.5-0.8-0.7-1.8-0.7-2.9c0-1.2,0.2-2.2,0.7-3.1c0.5-0.9,1.1-1.6,1.9-2.1c0.8-0.5,1.8-0.7,2.8-0.7c1,0,1.9,0.2,2.7,0.7
                        c0.8,0.4,1.3,1.1,1.8,2c0.4,0.8,0.6,1.8,0.6,3c0,0.2,0,0.4,0,0.8C180.8,27.2,173.2,27.2,173.2,27.2z M173.3,25.2h4.7
                        c-0.1-0.7-0.3-1.3-0.7-1.6c-0.4-0.3-0.9-0.5-1.5-0.5C174.5,23.1,173.6,23.8,173.3,25.2z"/>
                    <path d="M188.8,21c0.2,0,0.4,0,0.6,0v2.5c-0.2-0.1-0.5-0.1-0.8-0.1c-0.5,0-1,0.1-1.3,0.3c-0.5,0.2-0.8,0.5-1.1,1
                        c-0.2,0.4-0.4,1-0.4,1.6v5.5H183V21h2.4v1.7c0.3-0.5,0.7-1,1.2-1.2C187.2,21.2,187.9,21,188.8,21z"/>
                    <path d="M200.1,23.8c0.1,0.4,0.1,0.8,0.1,1.4v6.6h-2.4v-1.3c-0.5,0.6-1,1-1.6,1.2c-0.6,0.2-1.3,0.4-2.1,0.4
                        c-0.7,0-1.4-0.1-1.9-0.4c-0.5-0.3-1-0.7-1.2-1.2c-0.3-0.5-0.4-1-0.4-1.7c0-0.8,0.2-1.5,0.5-2c0.4-0.5,1-1,1.8-1.3
                        c0.5-0.2,1-0.3,1.7-0.4c0.7-0.1,1.6-0.3,2.9-0.5c-0.1-0.5-0.2-0.9-0.6-1.1c-0.3-0.2-0.8-0.4-1.4-0.4c-0.4,0-0.9,0.1-1.3,0.3
                        s-0.7,0.5-0.8,1l-2.4-0.8c0.3-0.9,0.8-1.7,1.6-2.2s1.8-0.8,3-0.8c2.1,0,3.5,0.7,4.2,2C199.9,23,200,23.4,200.1,23.8z M197.4,26.9
                        c-0.9,0.1-1.6,0.3-2.1,0.4c-0.4,0.1-0.8,0.2-1.1,0.3c-0.4,0.1-0.7,0.3-0.9,0.5c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.4,0.1,0.7,0.4,0.9
                        c0.3,0.2,0.6,0.3,1.1,0.3c0.4,0,0.9-0.1,1.2-0.2c0.4-0.1,0.6-0.4,0.8-0.6c0.2-0.2,0.4-0.4,0.4-0.6
                        C197.3,28.2,197.4,27.6,197.4,26.9z"/>
                    <path d="M204.6,31.4c-0.8-0.5-1.4-1.2-1.8-2c-0.4-0.9-0.7-1.8-0.7-2.9c0-1.1,0.2-2.1,0.7-2.9c0.4-0.9,1.1-1.6,1.9-2
                        c0.8-0.5,1.8-0.7,2.8-0.7c0.8,0,1.6,0.1,2.3,0.4c0.7,0.3,1.2,0.7,1.7,1.3c0.4,0.5,0.7,1.2,0.9,1.9l-2.7,0.7
                        c-0.1-0.6-0.4-1-0.8-1.3c-0.4-0.3-0.8-0.5-1.3-0.5c-0.8,0-1.4,0.3-1.8,0.9s-0.6,1.3-0.6,2.3c0,1,0.2,1.7,0.6,2.3
                        c0.4,0.6,1,0.9,1.8,0.9c0.6,0,1-0.1,1.4-0.4c0.3-0.3,0.6-0.7,0.7-1.2l2.8,0.6c-0.3,1.1-0.9,2-1.7,2.7c-0.9,0.6-1.9,0.9-3.2,0.9
                        C206.4,32.1,205.4,31.9,204.6,31.4z"/>
                    <path d="M217.7,23.1v3.9v0.6c0,0.4,0,0.6,0,0.8c0,0.2,0.1,0.4,0.2,0.5c0.3,0.4,0.8,0.7,1.6,0.7c0.3,0,0.7,0,1.3-0.1
                        v2.3c-0.7,0.1-1.3,0.2-1.9,0.2c-0.8,0-1.5-0.1-2.1-0.4s-1-0.6-1.3-1.2c-0.2-0.3-0.3-0.7-0.4-1c-0.1-0.4-0.1-0.7-0.1-1.2v-1v-4.1
                        h-1.8V21h1.8v-3h2.7v3h3.1v2.1H217.7z"/>
                    <path d="M224.9,19.5h-0.3c-0.7,0-1.2-0.5-1.2-1.2l0,0c0-0.7,0.5-1.2,1.2-1.2h0.3c0.7,0,1.2,0.5,1.2,1.2l0,0
                        C226.1,19,225.6,19.5,224.9,19.5z M226.1,31.8h-2.7V21h2.7V31.8z"/>
                    <path d="M234.8,31.8H232L228.1,21h2.7l2.6,7.5L236,21h2.7L234.8,31.8z" />
                    <path d="M242.4,27.2c0.1,0.8,0.4,1.4,0.8,1.8c0.4,0.4,1,0.6,1.7,0.6c0.5,0,1-0.1,1.4-0.4c0.4-0.2,0.7-0.6,0.9-1
                        l2.7,0.8c-0.3,0.7-0.7,1.2-1.1,1.7s-1,0.8-1.7,1.1c-0.6,0.2-1.3,0.4-2,0.4c-1.1,0-2.1-0.2-2.9-0.7c-0.8-0.5-1.5-1.1-2-2
                        c-0.5-0.8-0.7-1.8-0.7-2.9c0-1.2,0.2-2.2,0.7-3.1c0.5-0.9,1.1-1.6,1.9-2.1c0.8-0.5,1.8-0.7,2.8-0.7c1,0,1.9,0.2,2.7,0.7
                        c0.8,0.4,1.3,1.1,1.8,2c0.4,0.8,0.6,1.8,0.6,3c0,0.2,0,0.4,0,0.8C250,27.2,242.4,27.2,242.4,27.2z M242.5,25.2h4.7
                        c-0.1-0.7-0.3-1.3-0.7-1.6c-0.4-0.3-0.9-0.5-1.5-0.5C243.6,23.1,242.8,23.8,242.5,25.2z"/>
                </g>
            </g>
        </svg>
    )
}