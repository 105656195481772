import React from "react"
import { StraightCover } from "../layout/StraightCover";
import { Brand } from "../navigation/Brand"
import { BlobOne } from "./blobs/BlobOne";
import { BlobTwo } from "./blobs/BlobTwo";
import { BlobThree } from "./blobs/BlobThree";

interface Props {
    coverColour?: string,
    className?: string,
}

const footerInfo = {
    phone: "+1 (800) 732 4412",
    privacyPolicy: "/policy/privacy",
    facebook: "https://www.facebook.com/idreaminteractive",
    twitter: "https://twitter.com/idream_interact",
    instagram: "https://www.instagram.com/idreaminteractive/",
    linkedin: "https://www.linkedin.com/company/idream-interactive",
}

export const Footer: React.FC<Props> = ({ coverColour = "#ffffff", className = "" }) => {
    return (
        <footer className={`footer`}>
            <StraightCover fill={coverColour} className={className} />
            <div className="blobs">
                <div className="layer first-layer">
                    <BlobOne fill={"#ffffff08"} />
                </div>
                <div className="layer second-layer">
                    <BlobTwo fill={"#ffffff08"} />
                </div>
                <div className="layer third-layer">
                    <BlobThree fill={"#ffffff08"} />
                </div>
                <div className="layer fourth-layer">
                    <BlobThree fill={"#ffffff08"} />
                </div>
            </div>
            <div className="footer-container">
                <Brand />
                <div className="socials">
                    <a href={footerInfo.facebook} target="_blank" aria-label="Facebook" rel='noopener noreferrer'>
                        <svg width="36" height="36" viewBox="0 0 36 36">
                            <path d="M36,18c0-9.9-8.1-18-18-18S0,8.1,0,18c0,9,6.6,16.4,15.2,17.8V23.2h-4.6V18h4.6v-4c0-4.5,2.7-7,6.8-7
                                c2,0,4,0.3,4,0.3v4.4h-2.3c-2.2,0-2.9,1.4-2.9,2.8V18h5L25,23.2h-4.2v12.6C29.4,34.4,36,27,36,18z"/>
                        </svg>
                    </a>
                    <a href={footerInfo.twitter} target="_blank" aria-label="Twitter" rel='noopener noreferrer'>
                        <svg width="36" height="36" viewBox="0 0 36 36">
                            <path d="M18,0C8.1,0,0,8.1,0,18s8.1,18,18,18s18-8.1,18-18S27.9,0,18,0z M25.2,14.2c0,0.2,0,0.3,0,0.5
                                c0,4.9-3.8,10.6-10.6,10.6c-2,0-4-0.6-5.7-1.7c0.3,0,0.6,0.1,0.9,0.1c1.7,0,3.3-0.6,4.6-1.6c-1.6,0-3-1.1-3.5-2.6
                                c0.6,0.1,1.1,0.1,1.7-0.1c-1.7-0.4-3-1.9-3-3.7l0,0c0.5,0.3,1.1,0.5,1.7,0.5c-1.6-1.1-2.1-3.3-1.2-5c1.9,2.3,4.7,3.7,7.7,3.9
                                c-0.3-1.3,0.1-2.7,1.1-3.6c1.5-1.4,3.9-1.3,5.3,0.2c0.8-0.2,1.6-0.5,2.4-0.9c-0.3,0.9-0.9,1.6-1.6,2.1c0.7-0.1,1.5-0.3,2.1-0.6
                                C26.6,13,26,13.7,25.2,14.2z"/>
                        </svg>
                    </a>
                    <a href={footerInfo.instagram} aria-label="Instagram" rel='noopener noreferrer' target="_blank">
                        <svg width="36" height="36" viewBox="0 0 36 36">
                            <path d="M18,0C8.1,0,0,8.1,0,18s8.1,18,18,18s18-8.1,18-18S27.9,0,18,0z M27,21.6c0,1-0.2,1.6-0.4,2.2
                                c-0.2,0.6-0.5,1.1-1,1.6c-0.5,0.5-1,0.8-1.6,1c-0.6,0.2-1.2,0.4-2.2,0.4c-1,0-1.3,0-3.7,0c-2.5,0-2.8,0-3.7,0c-1,0-1.6-0.2-2.2-0.4
                                c-0.6-0.2-1.1-0.5-1.6-1c-0.5-0.5-0.8-1-1-1.6C9.2,23.3,9,22.6,9,21.6c0-1,0-1.3,0-3.7c0-2.5,0-2.8,0-3.7c0-1,0.2-1.6,0.4-2.2
                                c0.2-0.6,0.6-1.1,1.1-1.6c0.5-0.5,1-0.8,1.6-1c0.6-0.2,1.2-0.4,2.2-0.4c1,0,1.3,0,3.7,0c2.5,0,2.8,0,3.8,0c1,0,1.6,0.2,2.2,0.4
                                c0.6,0.2,1.1,0.5,1.6,1c0.5,0.5,0.8,1,1,1.6c0.2,0.6,0.4,1.2,0.4,2.2c0,1,0.1,1.3,0.1,3.7C27.1,20.4,27.1,20.7,27,21.6z"/>
                            <path d="M18,14.9c-1.7,0-3,1.3-3,3c0,1.7,1.4,3,3,3s3-1.4,3-3C21,16.2,19.7,14.9,18,14.9z" />
                            <path d="M25.1,12.5c-0.2-0.4-0.4-0.7-0.7-1c-0.3-0.3-0.6-0.5-1-0.7c-0.3-0.1-0.8-0.3-1.7-0.3c-0.9,0-1.2,0-3.7,0
                                s-2.7,0-3.7,0c-0.9,0-1.4,0.2-1.7,0.3c-0.4,0.2-0.7,0.4-1,0.7c-0.3,0.3-0.5,0.6-0.7,1c-0.1,0.3-0.3,0.8-0.3,1.7c0,1,0,1.2,0,3.7
                                s0,2.7,0,3.7c0,0.9,0.2,1.4,0.3,1.7c0.2,0.4,0.4,0.7,0.7,1c0.3,0.3,0.6,0.5,1,0.7c0.3,0.1,0.8,0.3,1.7,0.3c1,0,1.2,0,3.7,0
                                s2.7,0,3.7,0c0.9,0,1.4-0.2,1.7-0.3c0.4-0.2,0.7-0.4,1-0.7c0.3-0.3,0.5-0.6,0.7-1c0.1-0.3,0.3-0.8,0.3-1.7c0-0.9,0-1.2,0-3.7
                                s0-2.7,0-3.7C25.3,13.3,25.2,12.9,25.1,12.5z M18,22.6c-2.6,0-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7s4.7,2.1,4.7,4.7
                                C22.7,20.5,20.6,22.6,18,22.6z M22.8,14.1c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1S23.5,14.1,22.8,14.1z"/>
                        </svg>
                    </a>
                    <a href={footerInfo.linkedin} aria-label="LinkedIn" rel='noopener noreferrer' target="_blank">
                        <svg width="36" height="36" viewBox="0 0 36 36">
                            <path d="M18,0C8.06,0,0,8.06,0,18c0,9.94,8.06,18,18,18s18-8.06,18-18C36,8.06,27.94,0,18,0z M12,13.88
                            c-1.04,0-1.88-0.84-1.88-1.88s0.84-1.88,1.88-1.88s1.88,0.84,1.88,1.88S13.04,13.88,12,13.88z M13.5,15.5v10h-3v-10H13.5z
                            M25.5,25.5h-3v-5c0-1.33-0.53-2.5-1.77-2.5c-1.51,0-2.23,1.02-2.23,2.7v4.8h-3v-10h2.83v1.44h0.03c0.43-0.78,1.7-1.57,3.28-1.57
                            c3.02,0,3.86,1.6,3.86,4.58V25.5z"/>
                        </svg>
                    </a>
                </div>
                <p className="contact-info">{footerInfo.phone}</p>
                <p className="contact-info"><a href={footerInfo.privacyPolicy}>Privacy Policy</a></p>
                <p className="copyright">
                    <span>©</span> 2021 iDream Interactive
                </p>
            </div>
        </footer>
    )
}