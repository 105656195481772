import React from "react";

interface Props {
    fill: string;
}

export const BlobTwo: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="830" height="855" viewBox="0 0 830 855" fill={fill} className="blob-two">
            <path d="M263.5,533.8c33.3,1,65.8,2,94.8,6.8c46.2,7.6,93,28.2,118.5,67.4c35.9,55.2,20.8,124.2,28.3,185.7
            c2.6,21.1,11.5,43.1,29.9,53.8c14,8.1,31.1,8.3,47.3,7c57.4-4.3,117-22.4,159-63.4c48.5-47.3,57.5-121,67.7-184.9
            c5.9-36.9,11.8-73.8,17.6-110.7c2.7-17.2,5.4-34.9,0.8-51.7c-5.9-21.5-23.2-38.2-41.9-50.5c-92.8-61-200.4-103.9-258.8-204.6
            C403.3-23.8,183.4,0.9,183.3,0.9C128.1,7,79.7,35,48,80.9C11.5,133.7,11.8,206.2,5.9,267.7c-5.4,55.7-13.8,115,11,165.1
            C63.9,527.7,167.2,530.8,263.5,533.8z"/>
        </svg>
    )
}