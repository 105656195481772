import React from "react";

interface Props {
    fill: string;
}

export const BlobOne: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="850" height="725" viewBox="0 0 850 725" fill={fill} className="blob-one">
            <path d="M187.4,497.5c3.4,0.8,6.8,1.5,10.2,2.1c54.8,9.9,108.4,33.6,147.5,74.1c12.9,13.3,24,28.3,35.7,42.6
                c53.3,65.2,121.8,104.3,206.6,108.4C653.7,728,720,704.4,769,659.4c38.6-35.4,65-82.8,75.7-134.2c13.7-65.9,0.1-131.9-33.6-185.3
                c-22-34.9-38-73.1-49.4-112.8C743.1,162.5,692.1,109,622.6,90.3c-16.3-4.4-32.7-6.6-48.9-6.8c-70.3-0.9-139.2-20-201.2-53.4
                c-17.8-9.6-37-17.2-57.5-22.4C182.6-26.2,43.3,55.6,8.1,188C-26.7,318.8,53.8,466.8,187.4,497.5z"/>
        </svg>
    )
}