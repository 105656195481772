import React from "react"

interface Props {

}

export const LogoIcon: React.FC<Props> = ({ }) => {
    return (
        <svg className="brand desktop-hide" width="60" height="60" viewBox="0 0 60 60">
            <path d="M6.9,28.2C7,28,7,27.8,7.1,27.6c1.3-3.4,4.6-4.7,4.6-4.7c4-1.9,8.3,0,8.3,0c2.9-7.5,8.3-6.1,9.1-5.8
                    c1.7-2.9,4.9-4.8,8.5-4.8c5.5,0,9.9,4.4,9.9,9.9c0,0.8-0.1,1.6-0.3,2.4c3.3,0.7,5.9,2.2,6.2,2.4c0-8.2-0.4-13.1-3.7-16.4
                    C46,6.7,40.3,6.7,30.1,6.7c-10.2,0-15.9,0-19.6,3.7C7.1,13.9,6.9,19.1,6.9,28.2L6.9,28.2z"/>
            <path d="M60,39.9c-1.8,1.6-4.1,2.5-6.3,3.1c-2.6,0.7-5.2,1.2-7.9,1.4c-4.3,0.4-8.6,0.1-12.5-1.9
                    c-0.9-0.5-1.8-1.1-2.7-1.7c-0.1-0.1-0.9-0.8-0.9-0.8c-2.8,4.7-9.3,4.3-9.3,4.3C15,44.1,12.3,39,12.3,39c0.1,0.1-2.6,0.9-2.8,0.9
                    c-3,0.7-6.4,0.6-9.5,0.1c3,2.5,6.1,3.8,7.4,4.3c0.6,2.2,1.4,3.9,2.8,5.3c3.8,3.7,9.4,3.7,19.6,3.7c10.2,0,15.9,0,19.6-3.7
                    c1-1,1.8-2.2,2.3-3.7C56.2,45.2,60,39.9,60,39.9z"/>
        </svg>
    )
}