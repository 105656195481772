import React from "react"
import { Link } from "gatsby"
import { FullLogo } from "./FullLogo"
import { LogoIcon } from "./LogoIcon"

interface Props {

}

export const Brand: React.FC<Props> = ({ }) => {
    return (
        <Link to="/" aria-label="iDream Interactive">
            <FullLogo />
            <LogoIcon />
        </Link>
    )
}