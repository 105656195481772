import React from "react";

interface Props {
    fill: string;
}

export const BlobThree: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="210" height="200" viewBox="0 0 210 200" fill={fill} className="blob-three">
            <path d="M206.5,96.5c20.5,63.5-53.3,124.7-103.2,96.4C54.5,165.3,0,181,0,96.5C0,43.2,14.5-2.2,103.2,0.1
		    C224.5,3.1,190,45.5,206.5,96.5z"/>
        </svg>
    )
}